













import { Component, Prop, PropSync, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class HistoricalOutliersTable extends Vue {
  @PropSync("selected", { type: Array, default: () => [] }) selectedRows!: Object[];
  @Prop({ type: Boolean, default: false }) loading!: Boolean;
  @Prop({ type: Array, default: () => [] }) selection!: Object[];
  @Prop({ type: Array, default: () => [] }) headers!: Object[];
  item: any = {};
}
