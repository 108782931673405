




























































































































import { Vue, Component } from "vue-property-decorator";
import store from "@/store";
//  utilities
import dateHelper from "@/Scripts/utilities/date-helper";
import moment from "moment";
//  types
import { DataQualityHeader } from "@/types/dataQuality/dataQualityTable";
import { headers } from "./VDataTableHeaders/DataOutliers";
//  components
import ManageModal from "@/components/dataQuality/OutliersModals/ManageModal.vue";
import HistoricalModal from "@/components/dataQuality/OutliersModals/HistoricalModal.vue";
import ThresholdsModal from "@/components/dataQuality/OutliersModals/ThresholdsModal.vue";
import DataOutliersTable from "@/components/dataQuality/DataOutliersTable/index.vue";
//  modules
import { getModule } from "vuex-module-decorators";
import DataQualityModule from "@/store/clients/DataQuality.module";
import VesselsModule from "@/store/clients/Vessels.module";

const Vessels = getModule(VesselsModule, store);
const DataQuality = getModule(DataQualityModule, store);

@Component({
  components: {
    DataOutliersTable,
    ManageModal,
    HistoricalModal,
    ThresholdsModal,
  },
})
export default class DataOutliers extends Vue {
  searchQuery = "";
  ManageModal = false;
  HistoricalModal = false;
  ThresholdsModal = false;
  showAllData = false;
  dates: string[] = ["2019-09-10", "2019-09-20"];
  date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
  menu = false;
  headers: DataQualityHeader[] = headers;

  // @GETTERS
  get isEnvDevelopment(): boolean {
    return process.env.NODE_ENV === "development";
  }

  get dateRangeText() {
    return `${moment(this.dates[0]).format("ll")} - ${moment(this.dates[1]).format("ll")}`;
  }

  get dateRange() {
    return `${moment(this.dates[0]).format("DD MMM")} - ${moment(this.dates[1]).format("DD MMM")}`;
  }

  get dataOutliers(): any {
    var dataOutliers = DataQuality.dataOutliers.map((item, index) => ({
      key: index,
      vesselName: {
        value: Vessels.extendedVessels.find(vessel => vessel.id === item.vesselId)?.name ?? "Uknown Vessel",
        link: null,
      },
      overallStatus: {
        value: item.overallStatus,
        link: null,
      },
      stw: {
        value: item.data.find(row => row.featureName === "STW")?.value,
        link: null,
      },
      draft: {
        value: item.data.find(row => row.featureName === "Draft")?.value,
        link: null,
      },
      speedLog: {
        value: item.data.find(row => row.featureName === "speedLog")?.value,
        link: null,
      },
      gefoc1: {
        value: item.data.find(row => row.featureName === "gefoc1")?.value,
        link: null,
      },
      gefoc2: {
        value: item.data.find(row => row.featureName === "gefoc2")?.value,
        link: null,
      },
      settings: {
        value: null,
        link: null,
      },
    }));
    return dataOutliers;
  }
  // @METHODS
  // @HOOKS
  async created(): Promise<void> {
    await Promise.all([Vessels.refreshExtendedVessels(), DataQuality.refreshDataOutliers()]);
  }
}
