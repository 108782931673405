




















import { Component, Prop, Vue } from "vue-property-decorator";
//  components
import DataIndicator from "@/components/dataQuality/DataOutliersTable/DataIndicator.vue";
//  types
import { CellData, IndicatorData } from "@/types/dataQuality/dataQualityTable";

@Component({
  components: {
    DataIndicator,
  },
})
export default class DataCell extends Vue {
  @Prop({ type: Object }) data!: CellData;

  //  @Getters
  get isIndicator(): boolean {
    if (!this.data) return false;
    return this.data.type === "dataIndicator" || this.data.type === "percentIndicator";
  }

  get isActions(): boolean {
    if (!this.data) return false;
    return this.data.type === "settings";
  }

  get IndicatorData(): IndicatorData | undefined {
    if (!this.isIndicator) return;
    return {
      column: this.data.column,
      status: this.data.value,
      type: this.data.type,
      text: this.indicatorText,
      icon: this.indicatorIcon,
      align: this.data.align,
      link: null,
    };
  }

  get indicatorText(): string {
    if (this.data.column === "overallStatus") {
      switch (this.data.value) {
        case 1:
          return "No Outliers";
        case 2:
          return "Minor Outliers";
        case 3:
          return "Major Outliers";
        default:
          return "N/A";
      }
    } else {
      return this.data.value ? this.data.value.toString() : "";
    }
  }

  get indicatorIcon(): string {
    if (this.data.column === "overallStatus") {
      switch (this.data.value) {
        case 1:
          return "mdi-database-check";
        case 2:
          return "mdi-database-alert";
        case 3:
          return "mdi-database-alert";
        default:
          return "mdi-close-circle";
      }
    } else {
      return "mdi-close-circle";
    }
  }

  //  @Methods
  settings() {
    this.$emit("onSettingsClick");
  }
}
