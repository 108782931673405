







































































































































import { Vue, Component, Prop, PropSync } from "vue-property-decorator";
import store from "@/store";
import moment from "moment";
//  types
import { ManageOutliersRow } from "@/types/dataQuality/ManageOutliersRow";
import { DataQualityHeader } from "@/types/dataQuality/dataQualityTable";
//  components
import ManageOutliersTable from "@/components/dataQuality/ManageOutliersTable.vue";
//  modules

@Component({
  components: {},
})
export default class ThresholdsModal extends Vue {
  @PropSync("value", { required: true }) isModalActive!: boolean;
  @Prop({ type: String, default: "448px" }) width!: string;

  //  @Getters

  // @Methods
  open(): void {
    this.isModalActive = true;
    this.$emit("open");
  }

  close(): void {
    this.isModalActive = false;
    this.$emit("close");
  }

  //  @Hooks
}
