


























































import { Vue, Component, Prop, PropSync } from "vue-property-decorator";
import store from "@/store";
import moment from "moment";
//  types
import { ManageOutliersRow } from "@/types/dataQuality/ManageOutliersRow";
import { DataQualityHeader } from "@/types/dataQuality/dataQualityTable";
//  components
import ManageOutliersTable from "@/components/dataQuality/ManageOutliersTable.vue";
//  modules
import { getModule } from "vuex-module-decorators";
import DataQualityModule from "@/store/clients/DataQuality.module";
import VesselsModule from "@/store/clients/Vessels.module";

const DataQuality = getModule(DataQualityModule, store);
const Vessels = getModule(VesselsModule, store);

@Component({
  components: {
    ManageOutliersTable,
  },
})
export default class ManageModal extends Vue {
  @PropSync("value", { required: true }) isModalActive!: boolean;
  @Prop({ type: String, default: "1048px" }) width!: string;

  selectedRows: ManageOutliersRow[] = [];
  selectedColumn = "";
  searchTerm = "";
  headers: DataQualityHeader[] = [
    {
      text: "Vessel",
      type: "string",
      align: "start",
      value: "name",
      sortable: false,
    },
    { text: "Data Tag", type: "string", value: "tag" },
    { text: "Outlier Value", type: "string", value: "value" },
    { text: "Date & Time", type: "string", value: "timestamp" },
    { text: "Impacts", type: "string", value: "reasonCode" },
  ];

  //  @Getters
  get loading(): boolean {
    return DataQuality.modalLoadingState;
  }

  get filteredTags(): ManageOutliersRow[] {
    if (this.selectedColumn === null || this.selectedColumn === "") {
      return this.ManageOutliersRows;
    }
    if (this.searchTerm === null || this.searchTerm === "") {
      return this.ManageOutliersRows;
    }
    const headerKey = this.headers.find(header => header.text === this.selectedColumn)!.value;
    return this.ManageOutliersRows.filter(item => {
      const value = item[headerKey];
      if (typeof value === "string") {
        return value.toLowerCase().includes(this.searchTerm.toLowerCase());
      }
      if (typeof value === "number") {
        return value.toString().includes(this.searchTerm);
      }
      return false;
    });
  }

  get ManageOutliersRows(): ManageOutliersRow[] {
    var dataOutliers = DataQuality.dataOutliersTakeAction.map((item, index) => {
      const vessel = Vessels.extendedVessels.find(vessel => vessel.id === item.vesselId);
      return {
        key: index,
        name: vessel ? vessel.name : "Unknown Vessel",
        timestamp: moment(item.timestamp).format("lll"),
        tag: item.tag,
        value: item.value,
        reasonCode: item.reasonCode,
      };
    });
    return dataOutliers;
  }

  get isDisabled(): boolean {
    return this.selectedRows.length === 0;
  }

  // @Methods
  open(): void {
    this.isModalActive = true;
    this.$emit("open");
  }

  close(): void {
    this.isModalActive = false;
    this.$emit("close");
  }

  //  @Hooks
  async created(): Promise<void> {
    await Promise.all([Vessels.refreshExtendedVessels(), DataQuality.refreshDataOutliersTakeAction()]);
  }
}
