






































































































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import store from "@/store";
//  types
import { ExtendedVessel } from "@/types/Vessel";
//  modules
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";

const Vessels = getModule(VesselsModule, store);

@Component({
  components: {},
})
export default class ManageOutliersTable extends Vue {
  @PropSync("selected", { type: Array, default: () => [] }) selectedRows!: Object[];
  @Prop({ type: Boolean, default: false }) loading!: Boolean;
  @Prop({ type: Array, default: () => [] }) selection!: Object[];
  @Prop({ type: Array, default: () => [] }) headers!: Object[];
  item: any = {};
  isVesselFilterEnabled = false;
  filterByVessels: ExtendedVessel[] = [];
  filterByTags: any[] = [];

  //  @Getters
  get vessels(): ExtendedVessel[] {
    return Vessels.extendedVessels;
  }

  get dataTagList(): any[] {
    return ["Draft Mean", "Speed Through Water", "ME #1 FOC", "ME #2 FOC", "ME #3 FOC", "ME #4 FOC"];
  }

  //  @Methods
  clearSelection(): void {
    this.filterByVessels = [];
  }
}
